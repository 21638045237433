import React from 'react'
import {Link, graphql} from 'gatsby'

import Layout from '../../components/Layout'
import PageHeading from "../../components/PageHeading";

const CitiesPage = ({
                        data: {
                            allMarkdownRemark: {edges},
                            markdownRemark: {
                                frontmatter: {label, description}
                            }
                        }
                    }) => {

    return (
        <Layout title={label} description={description}>
            <PageHeading heading={label} className="title is-size-3 is-size-4-touch is-size-4-mobile"/>
            <section className="section">
                <div className="container content">
                    <div className="columns">
                        <div
                            className="column is-10 is-offset-1"
                            style={{marginBottom: '6rem'}}
                        >
                            <ul className="taglist">
                                {edges.map(edge => (
                                    <li key={edge.node.fields.slug}>
                                        <Link to={edge.node.fields.slug} className="button">
                                            {edge.node.frontmatter.label}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default CitiesPage;

export const citiesPageQuery = graphql`
    query CitiesQuery {
        markdownRemark(frontmatter: { templateKey: { eq: "categories" }, slug: { eq: "cities"} }) {
            frontmatter {
                label
                description
            }
        }
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { templateKey: { eq: "cities" } } }
        ) {
            edges {
                node {
                    fields {
                        slug
                    }
                    frontmatter {
                        label
                        description
                        templateKey
                    }
                    excerpt
                    id
                }
            }
        }
    }
`
